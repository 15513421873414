
import { Vue, Component } from "vue-property-decorator";
import { isIOS, isAndroid } from "mobile-device-detect";
import AppVersion from "../../models/app_version";
import mobileAppRoutes from "@/api/routes/mobile_app";

@Component
export default class VDownloadApp extends Vue {
  private current_version: AppVersion | null = null;

  private async created() {
    if (isIOS) {
      this.current_version = (
        await this.$api.get(mobileAppRoutes.ios, { params: { os: "iOS" } })
      ).data;
    } else if (isAndroid) {
      this.current_version = (
        await this.$api.get(mobileAppRoutes.ios, { params: { os: "Android" } })
      ).data;
    } else {
      this.current_version = (
        await this.$api.get(mobileAppRoutes.ios, { params: { os: "Android" } })
      ).data;
    }
  }

  private async goToDownload() {
    if (isIOS) {
      window.location.href =
        "https://apps.apple.com/us/app/capability-building/id6451151325";
    } else if (isAndroid) {
      this.$router.push({ name: "android" });
    } else {
      this.$notify({
        duration: 5000,
        type: "warn",
        group: "notifications",
        text:
          "Приложение доступно только для устройств под управлением iOS и Android"
      });
    }
  }
}
